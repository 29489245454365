import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow p-4 sm:p-6 md:p-8 lg:p-12">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
