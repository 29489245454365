import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 bg-[#c1c8e4] rounded-b-lg  border-white">
      <div className="flex flex-col md:flex-row  text-justify justify-between items-center">
        <div className="flex flex-col items-start mb-6 md:mb-0">
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Fast Forward India</h3>
            <p>Room-313, 3rd floor</p>
            <p>Student Activity Centre (SAC)</p>
            <p>IIT (ISM) Dhanbad</p>
            <p>Jharkhand - 826004</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p>
              President - Rupal Bhartiya 
            </p>
            <i className="fa fa-phone"></i> <a href="tel:+9413170587">+91 9413170587</a>
            <p>
              <i className="fa-solid fa-envelope"></i> <a href="mailto:ffi@iitism.ac.in">ffi@iitism.ac.in</a>
            </p>
          </div>
        </div>
        <div className="flex space-x-4 mt-5 md:mt-0">
          <a
            href="https://www.facebook.com/FastForwardIndia"
            className="flex items-center justify-center w-10 h-10 border border-blue-600 text-blue-600 rounded-full hover:bg-blue-600 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook-f text-xl"></i>
          </a>
          <a
            href="https://x.com/fastforwrdindia?s=20&t=TY9fED0yDGx0aM7OxuWJ2w"
            className="flex items-center justify-center w-10 h-10 border border-blue-400 text-blue-400 rounded-full hover:bg-blue-400 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <i className="fab fa-twitter text-xl"></i>
          </a>
          <a
            href="https://www.instagram.com/ffichanginglives/"
            className="flex items-center justify-center w-10 h-10 border border-pink-600 text-pink-600 rounded-full hover:bg-pink-600 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <i className="fab fa-instagram text-xl"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/ffichanginglives/"
            className="flex items-center justify-center w-10 h-10 border border-blue-700 text-blue-700 rounded-full hover:bg-blue-700 hover:text-white transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <i className="fab fa-linkedin-in text-xl"></i>
          </a>
        </div>
      </div>
      <div className="text-center text-lg mt-4">
        <p>
        <i className="fa-regular fa-copyright"></i> {new Date().getFullYear()} Fast Forward India
        </p>
      </div>
    </footer>
  );
}

export default Footer;
