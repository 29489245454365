import React from 'react';

const About = () => {
  return (
    <div className='p-8' style={{ backgroundColor: 'rgb(193, 200, 228)' }}>
      <h1 className='animated-text text-custom-gray text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8'>
        Our Vision
      </h1>
      <div className='p-4 mx-4 my-4 border-l-4 border-gray-400 bg-white dark:border-richblue-200 dark:bg-richblack-700'>
        <div className='text-base sm:text-lg md:text-xl italic font-medium leading-relaxed text-gray-900 dark:text-white'>
          "Never doubt that a small group of thoughtful, committed citizens can change the world. Indeed, it’s the only thing that ever has."
        </div>
        <div className='text-base sm:text-lg md:text-xl italic font-medium leading-relaxed text-gray-900 dark:text-white'>
          - Margaret Mead
        </div>
      </div>
      <div className='text-base sm:text-lg md:text-xl text-justify mb-4'>
        "To provide an opportunity to the college students in the country to work on the problems of society side by side with their studies."
      </div>
      <div className='text-base sm:text-lg md:text-xl text-justify my-4'>
        To let India soar towards being a developed country, we, the young citizens of the country, play a role in contributing to the sole motive. As students of the premier institution, we hold the capability of finding solutions to the problems of the less privileged, improving their standard of living by making impactful changes and paving the path to development without roadblocks. FFI provides an opportunity for college students to work on these problems alongside their academics, equipping the underprivileged with essential skills for employment. This experience not only fosters great ideas but also sensitizes youth to the needs of their disadvantaged countrymen and offers a creative outlet for their energy.
      </div>
      <div className="w-full border-t border-white my-4"></div>
      <div className="react-reveal sm:text-left text-center mx-2 text-xl lg:mx-4 md:text-5xl lg:text-6xl xl:text-7xl tracking-tighter pt-10 text-indigo-700 mb-3 animated-text text-custom-gray font-bold mt-12">
        Our Achievements
      </div>
      <div className='text-base sm:text-lg text-left space-y-4'>
        <p>More than 350 blood cases were handled by FFI Volunteers in 2024.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>More than 1000 units of blood were donated through donation camps throughout 2024.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Umeed 2024 witnessed record-breaking participation of over 1000+ students from more than 50 schools across the region of Dhanbad and 7 NGOs.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Awarded for the most blood donations (540+) to PMCH under a felicitation program organized by Jharkhand Aids Control Society at Patliputra Medical College and Hospital (PMCH).</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Fast Forward India, under the Centre for Societal Mission (CSM) of Indian Institute of Technology ISM Dhanbad, was awarded 'Yuva-Gaurav-Samman' by 'Jharia Coalfield Samiti', Dhanbad on 12-January-2020 to mark the Birth Anniversary of Swami Vivekananda.</p>
        <div className="w-full border-t border-white my-4"></div>
       
        <p> A record 393 units of blood were collected in a single day through Blood Donation Camp in 2023.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Awarded by The Indian Red Cross Society PMCH, Dhanbad, and State AIDS Control Society for the highest number of Blood Donation and Blood Case Handling for three consecutive years.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Two of our volunteers, Mr. Brajesh Kumar Meena and Mr. Yogesh Mittal, were awarded for their active volunteering in the state of Jharkhand on World Blood Donor Day on 14th June 2023.</p>
        <div className="w-full border-t border-white my-4"></div>
        <p>Sachin, an FFI classroom student, has got a job as Computer Operator at Diamond Hostel, IIT (ISM), Dhanbad.</p>
        <div className="w-full border-t border-white my-4"></div>
      </div>
    </div>
  );
};

export default About;
