import React, { useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import qr from "./ffiqr.png";
const SchoolForm = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    schoolName: "",
    transactionId: "",
    teacherName: "",
    teacherContact: "",
    totalStudents: "",
    nrityanganGroup: "",
    nrityanganSoloDuet: "",
    dhwani: "",
    resolved: "",
    mastermind: "",
    shayAurMaat: "",
    chitrahar: "",
    einsteinsPlayground: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);

  const navigate = useNavigate();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentScreenshot(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.teacherContact.length !== 10) {
      setLoading(false);
      toast.error("Contact number must be 10 digits.");
      return;
    }
    toast.info("Submitting Form");
    if (paymentScreenshot) {
      formData["paymentScreenshot"]= paymentScreenshot;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_GOOGLE_SCRIPT_URL_UMEED_SCHOOL_REG,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.result === "success") {
        toast.success("Form Submitted Successfully!");
        const schoolID = response.data.schoolID;
        navigate("/submitted", { state: { ...formData, schoolID } });
        setFormData({
          schoolName: "",
          teacherName: "",
          transactionId: "",
          teacherContact: "",
          totalStudents: "",
          nrityanganGroup: "",
          nrityanganSoloDuet: "",
          dhwani: "",
          resolved: "",
          mastermind: "",
          shayAurMaat: "",
          chitrahar: "",
          einsteinsPlayground: "",
        });
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
      if (error.response) {
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-4 bg-custom-bg rounded-2xl shadow-md">
      <h2 className="text-2xl font-bold mb-4">School Registration Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            School Name
          </label>
          <input
            type="text"
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter school name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Teacher’s Name
          </label>
          <input
            type="text"
            name="teacherName"
            value={formData.teacherName}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter teacher's name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Teacher’s Contact
          </label>
          <input
            type="number"
            min="0"
            name="teacherContact"
            value={formData.teacherContact}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter teacher's contact number"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Total No of Participants
          </label>
          <input
            type="number"
            min="0"
            name="totalStudents"
            value={formData.totalStudents}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter total number of students"
            required
          />
        </div>

        <h3 className="text-xl font-semibold mb-4">
          Participants in Individual Events
        </h3>
        {/* Input fields for each category */}
        {[
          {
            name: "nrityanganGroup",
            label: "Nrityangan (Group)",
            placeholder: "Enter number of group participants",
          },
          {
            name: "nrityanganSoloDuet",
            label: "Nrityangan (Solo/Duet)",
            placeholder: "Enter number of solo/duet participants",
          },
          {
            name: "dhwani",
            label: "Dhwani",
            placeholder: "Enter number of participants",
          },
          {
            name: "resolved",
            label: "Resolved",
            placeholder: "Enter number of participants",
          },
          {
            name: "mastermind",
            label: "Mastermind",
            placeholder: "Enter number of participants",
          },
          {
            name: "shayAurMaat",
            label: "Shay aur Maat",
            placeholder: "Enter number of participants",
          },
          {
            name: "chitrahar",
            label: "Chitrahar",
            placeholder: "Enter number of participants",
          },
          {
            name: "einsteinsPlayground",
            label: "Einstein’s Playground",
            placeholder: "Enter number of participants",
          },
        ].map(({ name, label, placeholder }) => (
          <div key={name} className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              {label}
            </label>
            <input
              type="number"
              name={name}
              min="0"
              value={formData[name] || ""}
              onChange={handleChange}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder={placeholder}
              required
            />
          </div>
        ))}

        <div>
          <p className="text-lg font-medium">Registration Fee: 100 Rs</p>
          <p className="text-sm text-gray-500">
            Please scan the QR code to make the payment
          </p>

          <img className="rounded-2xl mb-4" src={qr} alt="Payment QR Code" />

          <label
            htmlFor="transactionId"
            className="block text-sm font-medium text-gray-700"
          >
            Enter Transaction ID
          </label>
          <input
            type="text"
            id="transactionId"
            name="transactionId"
            value={formData.transactionId || ""}
            onChange={handleChange}
            className="block w-full mb-4 p-2 mt-2 border border-gray-300 rounded"
            placeholder="Transaction ID"
            required
          />
          <div className="mb-4">
            <label
              htmlFor="paymentScreenshot"
              className="block text-lg font-semibold mb-2"
            >
              Upload the Payment Receipt *
            </label>
            <input
              type="file"
              id="paymentScreenshot"
              name="paymentScreenshot"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full py-2"
              required
            />
            {paymentScreenshot && (
              <img
                src={`data:image/jpeg;base64,${paymentScreenshot}`}
                alt="Payment Screenshot"
                className="mt-2 max-w-full h-auto border border-gray-300 rounded-md"
              />
            )}
          </div>
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 text-white font-bold rounded-md ${
            loading
              ? "bg-blue-700 cursor-not-allowed opacity-70 flex items-center justify-center"
              : "bg-blue-400 hover:bg-blue-500"
          }`}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <svg
                aria-hidden="true"
                role="status"
                className="w-4 h-4 me-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Submitting...
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default SchoolForm;
