import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
// import fiitjee from "./fiitjee.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-4 mx-1 md:mx-3  mt-4 border-gray-400 bg-white">
      <nav className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-row md:gap-4 gap-1 mb-4 md:mb-0">
          <a href="/" rel="noopener noreferrer">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722862190/FFI/Logo/gpq3l0srvnvyyjzeg8k5.png"
              className="md:h-16  h-12 object-contain"
              alt="FFI"
            />
          </a>
          <a
            href="https://www.iitism.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722862179/FFI/Logo/k1que0mivblqni1tlkuy.png"
              className="md:h-16 h-12 object-contain"
              alt="IIT ISM Dhanbad"
            />
          </a>
          {/* <a
            href="https://www.fiitjee.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fiitjee} className="md:h-16 h-12 object-contain" alt="FIITJEE" />
          </a> */}
        </div>

        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>

        <div
          className={`md:flex flex-col md:flex-row transition-all duration-300 ease-in-out ${
            isOpen
              ? "max-h-screen opacity-100"
              : "max-h-0 opacity-0 overflow-hidden"
          }`}
          style={{ maxHeight: isOpen ? "500px" : "0" }}
        >
          <ul className="flex flex-col md:flex-row text-xs  gap-3 md:gap-6 w-full">
            <li>
              <NavLink to="/" end className="nav-link text-base md:text-lg ">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/initiatives"
                className="nav-link  text-base md:text-lg"
              >
                Initiatives
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/timeline"
                className="nav-link  text-base md:text-lg"
              >
                Timeline
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" className="nav-link  text-base md:text-lg">
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink to="/umeed" className="nav-link  text-base md:text-lg">
                Umeed
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/tshirt" className="nav-link text-base md:text-lg ">
                T-Shirt
              </NavLink>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
