import React from 'react';
import About from './About';
import Image from './Image';

const Home = () => {
  return (
   <div>
     <div className="xl:px-1 md:px-8 lg:px-16">
      <div className="flex flex-col-reverse md:flex-row sm:mt-16 p-3 items-center">
        <div className="flex-1 mb-8 md:mb-0">
          <h1 className="text-4xl md:text-6xl lg:text-6xl font-bold animated-text text-custom-gray">
            Changing lives.
          </h1>
          <p className="mt-5 text-base md:text-lg lg:text-xl text-justify text-gray-800">
            Fast Forward India (FFI) is a unique effort and an initiative
            undertaken by the students of IIT(ISM) Dhanbad. This organization
            is completely managed and administered by the young and enthusiastic
            students of the institution. Creative and powerful ways are implemented
            to tackle problems of the millions of underprivileged people in India. 
            The organization conducts blood donation camps, imparts basic computer 
            and English education, and numerous career counseling sessions in schools 
            to help the deprived students shape their future.
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <img
            src="https://res.cloudinary.com/dvjschjlg/image/upload/v1729975097/FFI/Logo/sxsi8l13udyzizhwtded.png"
            alt="Fast Forward India Logo"
            className="w-full h-72 max-w-md lg:max-w-lg object-contain"
          />
        </div>
      </div>
      <Image />
      <About />
    </div>
   </div>
  );
};

export default Home;
