import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const dishaImages = [
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277852/FFI/Disha/wahnuh7uxhoryypzut3t.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277850/FFI/Disha/qufeig01fic8sjwin2hr.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277853/FFI/Disha/kybvkm6ws94g5oeoxzaj.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277850/FFI/Disha/ldlfw2the5ehrhgkl6f1.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277851/FFI/Disha/dk0oqnyohzrg53ufivwm.jpg",
];

const Disha = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-center text-custom-orange mb-6 animated-text">
        Disha
      </h1>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Overview</h2>
        <p className="text-base sm:text-lg">
          Concerning the alarming dropout rates after the 10th standard in Dhanbad, the DISHA chapter was started with the motto of spreading knowledge and awareness among students of the 10th and 12th standards regarding higher studies and various career options.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Working</h2>
        <p className="text-base sm:text-lg">
          We visit various government-aided and small private schools for career counseling for 10th and 12th standard students. We also conduct online career counseling via the Facebook Page "Disha Career Counseling Forum" and work on various videos related to career counseling. Currently, we provide guidance on about 25+ career options, as well as information on Olympiads and entrance exams like KVPY. To date, we have provided career counseling sessions to over 500 students.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Schools Covered</h2>
        <ul className="list-disc pl-4 mt-4 text-base sm:text-lg">
          <li className="mb-2">Kids Garden, Hirapur</li>
          <li className="mb-2">Balika Shiksha Sansthan, City Center</li>
          <li className="mb-2">Symbiosis Public School, Barwadda</li>
          <li className="mb-2">Koylanchal Public School</li>
          <li className="mb-2">Abhoya Sundari, Hirapur</li>
          <li className="mb-2">Higher Education, Vistipara</li>
          <li className="mb-2">Kendriya Vidyalaya-2, Jagjeevan Nagar</li>
          <li className="mb-2">Zila School, City Center</li>
        </ul>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Glimpse</h2>
      </section>

      <div className="relative overflow-hidden">
        <Slider {...settings} ref={sliderRef} className="whitespace-nowrap">
          {dishaImages.map((url, index) => (
            <div
              key={index}
              className="inline-block w-full h-72 md:h-80 lg:h-96 xl:h-[728px]"
            >
              <img
                src={url}
                alt={`Disha ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
              />
            </div>
          ))}
        </Slider>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {dishaImages.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-3 h-3 sm:w-4 sm:h-4 rounded-full cursor-pointer border-2 ${
                currentIndex === index
                  ? "bg-caribbeangreen-500 border-caribbeangreen-500"
                  : "bg-white border-gray-400"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Disha;
