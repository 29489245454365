import React from 'react';
const timelineData = [
  { year: 2007, event: "Fast Forward India (FFI) was started with a chapter ", link: ["/initiatives/bloodline"], ldata: ["Bloodline"] },
  { year: 2008, event: "FFI introduced two more new chapters named ", link: ["/initiatives/escape", "/initiatives/jagriti"], ldata: ["Escape", "Jagriti"] },
  { year: 2009, event: "FFI launched a new and overall its fourth chapter named ", link: ["/initiatives/disha"], ldata: ["Disha" ]},
  { year: 2010, event: "FFI launched the quiz event 'BRAINSTORM'", link: "#" },
  { year: 2014, event: "FFI set the milestone of a record blood donation of '905' units in a single day", link: "#" },
  { year: 2015, event: "FFI launched its Annual Festival UMEED, (previously known as 'CARRUS') a Career Development Fest", link: "#" },
  { year: 2016, event: "FFI started a campaign known as MEGA BLOOD DONATION CAMP (MBDC) in which Blood donation events are organized at various hospitals of the city", link: "#" },
  { year: 2016, event: "FFI initiated a campaign ISM to IIT (ISM) Movement which later helped ISM to become IIT", link: "#" },
  { year: 2019, event: "Initiative started to prepare students for Navodaya Entrance Examination", link: "#" },
  { year: 2020, event: "Started an Online Education System 'AAGAZ'", link: "#" },
  { year: 2023, event: "Awarded for the most blood donations (540+) to PMCH under a felicitation program organized by Jharkhand Aids Control Society", link: "#" },
  { year: 2023, event: "Umeed 2023 witnessed record-breaking participation of over 1000+ students", link: "#" },
];
const Timeline = () => {
  return (
    <div>
      
      <div>
      <p className="text-4xl animated-text uppercase tracking-wide text-custom-purple ">The Timeline of</p>
        <p className="text-2xl md:text-4xl font-bold leading-tight mb-2 uppercase text-custom-gray animated-text">Our Journey So Far</p>
        <p className="text-sm md:text-base text-gray-700 mb-4">
          Here’s the line of actions we took to reach this point. And we are still on the way to make it better.
        </p>
      </div>
    <div className="container mx-auto flex flex-col md:flex-row my-12 md:my-24 w-3/4">
     

      <div className="flex-1 md:ml-12 lg:w-3/4">
        <div className="relative p-4 md:p-10">
          <div
            className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 border-l-2 md:border-l-4 lg:border-l-8"
            style={{ borderColor: 'rgb(67, 56, 202)' }}
          ></div>

          {timelineData.map((item, index) => (
            <div
              key={index}
              className={`mb-8 flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row gap-40 '} items-start`}
            >
              <div className="w-full md:w-6/12 xl:w-5/12 px-4">
              </div>
              <div className="w-full md:w-6/12 xl:w-7/12 px-4 py-4">
                <p className="mb-3 text-lg text-custom-purple text-center ">{item.year}</p>
                <p className="text-sm md:text-base text-gray-700 w-5/6">
                  {item.event}
                  {Array.isArray(item.link) ? (
                    item.link.map((link, linkIndex) => (
                      <React.Fragment key={linkIndex}>
                        <a
                          href={link}
                          target="_blank"
                          className={`font-semibold underline text-custom-purple hover:text-custom-orange transition-colors duration-300 ${linkIndex === 0 ? 'text-red-700' : linkIndex === 1 ? 'text-sky-700' : 'text-violet-700'}`}
                          rel="noopener noreferrer"
                        >
                          {item.ldata[linkIndex]}
                        </a>
                        {linkIndex < item.link.length - 1 && ', '}
                      </React.Fragment>
                    ))
                  ) : (
                    <a
                      href={item.link}
                      target="_blank"
                      className="font-semibold underline text-orange-700"
                      rel="noopener noreferrer"
                    >
                      {item.ldata}
                    </a>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
        <img
          className="mx-auto -mt-40"
          src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722890069/FFI/Timeline/nqe1pxn52ebrf3enxa5p.jpg"
          alt="Timeline"
        />
      </div>
    </div>
    </div>

  );
};

export default Timeline;
