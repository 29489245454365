import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const jagritiimages = [
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723276543/FFI/Jagriti/scxl1id4tafwrfrgx4jg.png",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277080/FFI/Jagriti/kk27migkzxn1rfpg3dl4.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1723277079/FFI/Jagriti/p1m9ybmw4suk55pbzjf2.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722886664/FFI/Jagriti/ionnhuyeg1euq2fhxpds.webp",
];

const Jagriti = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-5xl md:text-7xl font-bold text-center text-[rgb(109,40,217)] mb-6 animated-text">
        Jagriti
      </h1>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          Overview
        </h2>
        <p className="text-base md:text-lg">
          Jagriti aims to provide tuition and Navodaya Entrance preparation to
          the underprivileged children in the slums surrounding ISM. We offer
          basic education and conduct various practice tests at our FFI Centre.
          Jagriti focuses on preparation for IX and X Board Examinations,
          reconnecting students with society, and preparing them for JNVST
          (Navodaya Exam).
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          About
        </h2>
        <p className="text-base md:text-lg text-justify">
          In today’s competitive world, survival depends not on being the
          fittest but on being adaptable to change. Life has become so fast that
          we rely heavily on machines, and computer education has become
          indispensable for professional success. In India, where many are
          deprived of basic necessities, computer education remains a distant
          dream for underprivileged children. To address this, Jagriti was
          proposed as part of Fast Forward India to offer basic computer
          education free of cost to underprivileged children in and around
          Dhanbad. Since its inception in 2008, Jagriti has been actively
          pursuing its goals to empower these children with essential computer
          skills, helping them make their mark in this computer-oriented world.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          Our Vision
        </h2>
        <p className="text-base md:text-lg">
          Jagriti was started with the vision to empower underprivileged
          students with computer education, enabling them to stand out in
          India’s rapidly changing era. We aim to connect those disconnected
          from society, helping them transform themselves to live with dignity
          and contribute meaningfully to society.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          Working
        </h2>
        <p className="text-base md:text-lg">
          Admissions for all Jagriti courses start at the beginning of each
          academic session. Children are divided into batches based on age and
          existing computer knowledge. Jagriti volunteers conduct regular theory
          and practical classes at the FFI centre.
        </p>
        <p className="text-base md:text-lg mt-4">
          The various courses under Jagriti include:
        </p>
        <p className="text-base md:text-lg mt-4">
          <strong>Aadhar:</strong> Under this project, students receive very
          basic computer knowledge. The beginner’s course, lasting 10 weeks, is
          designed for students with no prior computer knowledge and includes a
          final test.
        </p>
        <ul className="list-disc pl-4 mt-4 text-base md:text-lg">
          <li className="mb-2">
            Understanding the Hardware of the computer and their functions.
          </li>
          <li className="mb-2">
            Basic operational understanding of the Windows Interface.
          </li>
        </ul>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          School Campaign Centres:
        </h2>
        <ul className="list-disc pl-4 mt-4 text-base md:text-lg">
          <li className="mb-2">Middle School, Saraidhela</li>
          <li className="mb-2">Variye Buniyadi School, Jagjeevan Nagar</li>
          <li className="mb-2">Abhoya Sundari Orphanage</li>
        </ul>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          Location
        </h2>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.369612162675!2d86.44118111557628!3d23.819797184551212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef9c8b5839a6f3%3A0x8a5d4c56a68c1a5c!2sLahbani%20Dhaiya%20Chanchani%20Colony%2C%20Dhanbad%2C%20Jharkhand%20826004!5e0!3m2!1sen!2sin!4v1685608092933!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg shadow-md"
        ></iframe>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl md:text-5xl font-semibold text-custom-gray mb-4">
          Glimpse
        </h2>
        <div className="relative overflow-hidden">
          <Slider {...settings} ref={sliderRef} className="whitespace-nowrap">
            {jagritiimages.map((url, index) => (
              <div
                key={index}
                className="inline-block w-full md:w-1/2 lg:w-1/3 xl:w-1/4 h-72 md:h-80 lg:h-96 xl:h-[728px]"
              >
                <img
                  src={url}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                />
              </div>
            ))}
          </Slider>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2 pb-4">
            {[0, 1, 2, 3].map((index) => (
              <div
                key={index}
                onClick={() => handleDotClick(index)}
                className={`w-3 h-3 md:w-4 md:h-4 rounded-full cursor-pointer border-2 ${
                  currentIndex === index
                    ? "bg-caribbeangreen-500 border-caribbeangreen-500"
                    : "bg-white border-gray-400"
                }`}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Jagriti;
