import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Form = () => {
  const formRef = useRef(null);
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [paymentQR] = useState(process.env.REACT_APP_PAYMENT_QR_URL);
  const [sizeImage] = useState(process.env.REACT_APP_SIZE_IMAGE_URL);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState("No");
  const [size, setSize] = useState("S");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentScreenshot(reader.result.split(",")[1]); 
      };
      reader.readAsDataURL(file); 
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info("Submitting Form");
    const formData = new FormData();
    const elements = formRef.current.elements;
    for (let i = 0; i < elements.length; i++) {
      const field = elements[i];
      if (field.name && field.type !== "file") {
        formData.append(field.name, field.value);
      }
    }
    formData.append("paymentQR", paymentQR);
    formData.append("size", size);
    if (paymentScreenshot) {
      formData.append("paymentScreenshot", paymentScreenshot);
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_GOOGLE_SCRIPT_URL,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Form Submitted Successfully!");
      formRef.current.reset(); 
      setPaymentScreenshot(null);
      setDiscount("No");
      setSize("S");
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
      if (error.response) {
        console.error("Error response data:", error.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center p-6 text-center bg-[#c1c8e4] rounded-lg shadow-lg max-w-2xl w-full mx-auto mb-8 border-2 border-pink-400">
        <h1 className="text-4xl font-extrabold mb-4 animated-text">
          Order Your T-Shirt as a Blood Donor
        </h1>

        <p className="text-lg text-gray-800 mb-6 leading-relaxed">
          Fast Forward India is dedicated to making meaningful contributions to
          society through education, blood donation drives, and career guidance.
          FFI expresses gratitude for previous support for merchandise, which
          received positive feedback.
        </p>

        <p className="text-lg text-gray-800 mb-6 leading-relaxed">
          We are pleased to offer the merchandise yet again for those who missed
          grabbing it the last time. The price for the t-shirt is ₹359 (10% off)
          for those who donate blood at our Blood Donation Camp on 15th August
          2024 and ₹399 otherwise.
        </p>

        <p className="text-md text-gray-600 italic mb-4">
          Note: Orders will only be processed once the necessary threshold is
          reached and payment is received.
        </p>
      </div>

      <div className="flex justify-center items-center p-8 bg-[#c1c8e4] rounded-lg shadow-md max-w-2xl mx-auto my-8">
        <form ref={formRef} onSubmit={handleSubmit} className="w-full">
          <div className="mb-4">
            <label htmlFor="email" className="block text-lg font-semibold mb-2">
              E-mail address *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block text-lg font-semibold mb-2">
              Name *
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="admNo" className="block text-lg font-semibold mb-2">
              Admission Number *
            </label>
            <input
              type="text"
              id="admNo"
              name="admNo"
              placeholder="Admission Number *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mobileNo" className="block text-lg font-semibold mb-2">
              Mobile Number *
            </label>
            <input
              type="tel"
              id="mobileNo"
              name="mobileNo"
              placeholder="Mobile Number *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="hostel" className="block text-lg font-semibold mb-2">
              Hostel *
            </label>
            <input
              type="text"
              id="hostel"
              name="hostel"
              placeholder="Hostel *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="roomNo" className="block text-lg font-semibold mb-2">
              Room Number *
            </label>
            <input
              type="text"
              id="roomNo"
              name="roomNo"
              placeholder="Room Number *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="discount" className="block text-lg font-semibold mb-2">
              Want to buy our t-shirt with discount? *
            </label>
            <select
              id="discount"
              name="discount"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div className="mb-4">
            <p className="text-lg font-semibold mb-2">
              Complete the Payment using the QR code below:
            </p>
            <div className="flex items-center justify-center">
              <img
                src={paymentQR}
                alt="Payment QR"
                className="w-full max-w-md border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="paymentId" className="block text-lg font-semibold mb-2">
              Transaction ID of Payment *
            </label>
            <input
              type="text"
              id="paymentId"
              name="paymentId"
              placeholder="Transaction ID *"
              required
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="paymentScreenshot" className="block text-lg font-semibold mb-2">
              Upload the Payment Receipt *
            </label>
            <input
              type="file"
              id="paymentScreenshot"
              name="paymentScreenshot"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full py-2"
              required
            />
            {paymentScreenshot && (
              <img
                src={`data:image/jpeg;base64,${paymentScreenshot}`}
                alt="Payment Screenshot"
                className="mt-2 max-w-full h-auto border border-gray-300 rounded-md"
              />
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="size" className="block text-lg font-semibold mb-2">
              Choose your size:
            </label>
            <div className="flex items-center justify-center">
              <img
                src={sizeImage}
                alt="Size QR"
                className="w-full max-w-md border border-gray-300 rounded-md"
              />
            </div>
            <select
              id="size"
              name="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className="w-full mt-2 border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </select>
          </div>
          <div className="mb-4">
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 text-white font-bold rounded-md ${
                loading
                  ? "bg-blue-700 cursor-not-allowed opacity-70 flex items-center justify-center"
                  : "bg-blue-400 hover:bg-blue-500"
              }`}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Submitting...
                </span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="text-center p-6 bg-custom-bg rounded-lg shadow-lg max-w-2xl w-full mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Facing Issues?</h2>
        <p className="text-lg mb-4">If you encounter any issues with the form or have questions, please feel free to contact us:</p>
        <p className="text-lg mb-2">
          Bhawani Shankar Sarswat: <a href="tel:+917239979269" className="text-blue-600 hover:underline">7239979269</a>
        </p>
        <p className="text-lg mb-2">
          Nitin Kumar: <a href="tel:+917073101338" className="text-blue-600 hover:underline">7073101338</a>
        </p>
      </div>
    </div>
  );
};

export default Form;
