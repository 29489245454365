import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Gallery from "./Components/Gallery";
import Initiatives from "./Components/Initiatives";
import TimeLine from "./Components/TimeLine";
import Umeed from "./Components/Umeed";
import MainHeader from "./Components/MainHeader";
import Layout from "./Components/Layout";
import Bloodline from "./Components/Bloodline";
import Disha from "./Components/Disha";
import Escape from "./Components/Escape";
import Jagriti from "./Components/Jagriti";
import Form from "./Components/Form";
import { ToastContainer } from "react-toastify";
// import Bloodcamp from "./Components/Bloodcamp";
// import FormGuru from "./Components/FormGuru";
// import FormJalan from "./Components/FormJalan";
// import NotFound from "./Components/NotFound";
import SchoolForm from "./Components/SchoolRegistration";
import StudentRerister from "./Components/StudentRegistration";
import SubmittedData from "./Components/SubmittedData";
function App() {
  
  // if (window.location.protocol !== "https:") {
  //   window.location.href =
  //     "https://" +
  //     window.location.href.substring(window.location.protocol.length);
  // }
  return (
    <div className="App border-3 rounded-3xl overflow-x-hidden overflow-y-scroll bg-white min-h-screen ">
      <ToastContainer />
      <Layout>
        <Routes>
          <Route path="/" element={<MainHeader />}>
            <Route index element={<Home />} />
            <Route path="/submitted" element={<SubmittedData />} />
            <Route path="/initiatives" element={<Initiatives />} />
            <Route path="/timeline" element={<TimeLine />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/umeed" element={<Umeed />} />
            <Route path="/initiatives/bloodline" element={<Bloodline />} />
            <Route path="/initiatives/disha" element={<Disha />} />
            <Route path="/initiatives/escape" element={<Escape />} />
            <Route path="/initiatives/jagriti" element={<Jagriti />} />
            <Route path="/about" element={<About />} />
            <Route path="/tshirt" element={<Form />} />
            <Route path="/schreg" element={<SchoolForm />} />
            <Route path="/stdreg" element={<StudentRerister />} />
            {/* <Route path="/pmch" element={<Bloodcamp />} /> */}
            {/* <Route path="/gurukripa" element={<FormGuru />} /> */}
            {/* <Route path="/jalan" element={<FormJalan />} /> */}
            {/* <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
