import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SubmittedData = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state;

  useEffect(() => {
    if (!data) {
      alert("No data found. Please fill out the form first.");
      navigate("/schreg");
    }
  }, [data, navigate]);

  const handleLink = (e) => {
    e.preventDefault();
    navigate("/umeed"); 
  };

  if (!data) {
    return (
      <div className="text-center p-4">
        <h2 className="text-xl font-bold">Redirecting...</h2>
      </div>
    );
  }

  return (
    <div className="max-w-xl mx-auto p-4 bg-custom-bg rounded-2xl shadow-md">
      <h2 className="text-2xl font-bold mb-4">Form Submitted Successfully!</h2>
      <p className="mb-2">
        <strong>School ID:</strong> {data.schoolID}
      </p>
      <p className="mb-2">
        <strong>School Name:</strong> {data.schoolName}
      </p>
      <p className="mb-2">
        <strong>Teacher’s Name:</strong> {data.teacherName}
      </p>
      <p className="mb-2">
        <strong>Teacher’s Contact:</strong> {data.teacherContact}
      </p>
      <p className="mb-2">
        <strong>Total Participants:</strong> {data.totalStudents}
      </p>
      <h3 className="text-xl font-semibold mt-4">Event Participation</h3>
      <ul>
        <li>
          <strong>Nrityangan (Group):</strong> {data.nrityanganGroup}
        </li>
        <li>
          <strong>Nrityangan (Solo/Duet):</strong> {data.nrityanganSoloDuet}
        </li>
        <li>
          <strong>Dhwani:</strong> {data.dhwani}
        </li>
        <li>
          <strong>Resolved:</strong> {data.resolved}
        </li>
        <li>
          <strong>Mastermind:</strong> {data.mastermind}
        </li>
        <li>
          <strong>Shay aur Maat:</strong> {data.shayAurMaat}
        </li>
        <li>
          <strong>Chitrahar:</strong> {data.chitrahar}
        </li>
        <li>
          <strong>Einstein’s Playground:</strong> {data.einsteinsPlayground}
        </li>
      </ul>

      <div className="mt-4">
        <button
          onClick={handleLink}
          className="text-blue-600 hover:text-blue-700 underline text-xl"
        >
         Go to Individual Event Registration
        </button>
      </div>
    </div>
  );
};

export default SubmittedData;
