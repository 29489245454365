import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const events = [
  {
    title: "Nrityangan",
    description: `The Nrityangan is a platform for you to showcase your talent and passion for dance. Showcase your moves and expressions on the dance floor. It's an experience to let your passion for dance shine through.`,
    quote: `“To dance is to be out of yourself. Larger, more beautiful, more powerful. This is power, it is glory on earth and it is yours for the taking.” — Agnes de Mille`,
  },
  {
    title: "Chitrahaar",
    description: `Chitrahaar gives your inner artist the opportunity to showcase your brilliance and compete against other young artists in Dhanbad. So take out your pen and paper and show the magic of your mind.`,
    quote: `“Drawing is a way of coming upon the connection between things, just like metaphor in poetry reconnects what has become separated.” — John Berger`,
  },
  {
    title: "Resolved",
    description: `Resolved is the platform where young minds battle each other in a series of arguments and counter-arguments. The one who successfully gets their opinions across truly knows the art of debates.`,
    quote: `“It is better to debate a question without settling it than to settle a question without debating it.” — Joseph Joubert`,
  },
  {
    title: "Mastermind",
    description: `Mastermind is the unique platform where everyone tests their knowledge and grows their curious minds. A good quizzer is someone who is knowledgeable, quick-witted, and able to think on their feet.`,
    quote: `"Quizzes are opportunities dressed as questions. Embrace them, and you'll be one step closer to excellence." — Ashok Mahala`,
  },
  {
    title: "Einsteins Playground",
    description: `Einsteins Playground is the place where all the geniuses and innovators meet and demonstrate their scientific models and projects that could one day shape up the future. Just a small step by you might be a giant step for humanity.`,
    quote: `“Equipped with his five senses, man explores the universe around him and calls the adventure Science.” — Edwin Hubble`,
  },
  {
    title: "Dhwani",
    description: `Dhwani lets you exhibit the melody of your voice and your passion for music. So, take out your inner musician and let your surroundings drown in the wave of melodies. After all, wherever words fail, music always speaks.`,
    quote: `“Music is a moral law. It gives soul to the universe, wings to the mind, flight to the imagination, and charm and gaiety to life and to everything” — Plato`,
  },
  {
    title: "Shay Aur Maat",
    description: `Shay-Aur-Maat presents this opportunity for the small grandmasters to expand their minds and showcase their witty tactics to win the ultimate complex game.`,
    quote: `“Strategy requires thought, tactics require observation” — Max Euwe`,
  },
];

const images = [
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894415/FFI/Umeed/gpgzvujkzd3pqannvhph.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894408/FFI/Umeed/wva3ooasvmq38m9nhgqr.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894411/FFI/Umeed/f5r5yuopzdl24cejtypc.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894284/FFI/Umeed/hqu5fzxzjy4qeop4wsju.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894409/FFI/Umeed/onjvyc6lobjjmekkmwbj.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894408/FFI/Umeed/xjg5ilzoytwege3br0wy.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894408/FFI/Umeed/vphzwhiln9q4bs0nmghu.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894403/FFI/Umeed/ljxo3waerslsulq2furs.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894403/FFI/Umeed/l4ktu4hmntzn1ezhdu4i.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894369/FFI/Umeed/owgerjcnk9ciav8yvtuw.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894366/FFI/Umeed/jwbpbvr5tulg3dnjl5yz.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894324/FFI/Umeed/nz9vmkcesow59xl0oyul.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894302/FFI/Umeed/cmybwoe5ifanzttskjeb.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722894284/FFI/Umeed/zjahwqhpkvn1kujpsxzj.jpg",
];

const Umeed = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentIndex(current),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  const navigate = useNavigate();
  const handleSchool = (e) => {
    e.preventDefault();
    navigate("/schreg");
  };
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl sm:text-6xl md:text-8xl font-bold text-center mb-4 text-custom-gray animated-text">
        UMEED 2025
      </h1>
      <button
        className="text-xl sm:text-2xl md:text-4xl mb-8 underline text-custom-orange "
        onClick={handleSchool}
      >
        Register For UMEED 2025{" "}
      </button>

      <p className="text-base sm:text-lg md:text-xl mb-12 bg-custom-bg rounded-xl p-6 sm:p-8 md:p-10 text-justify">
        UMEED is an annual celebration that embodies the very essence of hope.
        Each year, the spirit of Umeed blossoms anew within the vibrant tapestry
        of IIT(ISM) Dhanbad. This hallmark event transcends the realm of a mere
        college festival. It becomes a crucible where aspirations are ignited,
        talents showcased, and dreams take flight.
        <br />
        <br />
        Umeed 2024 witnessed record-breaking participation of over 1000+
        students from more than 50 schools across the region of Dhanbad and 7
        NGOs. Umeed's true essence lies in its spirit of inclusivity and
        empowerment. It extends a welcoming hand to underprivileged students
        from across the region, providing them a platform to shine.
        <br />
        <br />
        At the heart of UMEED, a vibrant mosaic of intellectual pursuits and
        artistic expression unfolds. Competitive quizzes of Mastermind challenge
        minds, while spellbinding dances of Dhwani and musical performances of
        Nrityangan paint the canvas of imagination. Chitrahaar showcases young
        artists igniting their creativity, while tech enthusiasts gather for the
        Einsteins Playground science exhibition. The art of debating is tested
        at Resolved, and the royal game of chess is played in Shay-Aur-Maat.
      </p>

      {events.map((event, index) => (
        <div
          key={index}
          className="mb-12 rounded-xl p-6 sm:p-8 md:p-10 bg-custom-bg"
        >
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-4">
            {event.title}
          </h2>
          <p className="text-base sm:text-lg md:text-xl mb-4">
            {event.description}
          </p>
          <blockquote className="border-l-4 border-blue-500 pl-4 italic text-base sm:text-lg md:text-xl bg-white p-4 rounded dark:text-white dark:bg-richblack-700">
            {event.quote}
          </blockquote>

          <div>
            {event.title === "Nrityangan" && (
              <div>
                <div>
                  <button
                    className="text-xl sm:text-2xl md:text-4xl  underline text-custom-orange"
                    onClick={() =>
                      navigate("/stdreg", {
                        state: { event: "Nrityangan(Solo/Duet)" },
                      })
                    }
                  >
                    Register for Nrityangan (Solo/Duet)
                  </button>
                </div>
                <button
                  className="text-xl sm:text-2xl md:text-4xl  underline text-custom-orange mt-2"
                  onClick={() =>
                    navigate("/stdreg", {
                      state: { event: "Nrityangan(Group)" },
                    })
                  }
                >
                  Register for Nrityangan (Group)
                </button>
              </div>
            )}
            {event.title !== "Nrityangan" && (
              <button
                className="text-xl sm:text-2xl md:text-4xl  underline text-custom-orange mt-2"
                onClick={() =>
                  navigate("/stdreg", { state: { event: event.title } })
                }
              >
                Register for {event.title}
              </button>
            )}
          </div>
        </div>
      ))}

      <Slider {...settings} ref={sliderRef} className="mb-12">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative w-full h-60 sm:h-80 md:h-96 xl:h-[728px]"
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </Slider>

      <div className="flex justify-center mt-4">
        {images.map((_, index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)}
            className={`sm:w-4 sm:h-4 w-2 h-2 mx-2  rounded-full cursor-pointer border-2 ${
              currentIndex === index
                ? "bg-caribbeangreen-500 border-caribbeangreen-500"
                : "bg-white border-gray-400"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Umeed;
