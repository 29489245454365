import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const StudentRerister = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      alert("No data found. Please Choose event to Register.");
      navigate("/umeed");
    }
  }, [data, navigate]);
  const [formData, setFormData] = useState({
    event: data ? data.event : "",
    schoolId: "",
    teams: [[{ name: "", class: "", contactNo: "" }]],
    audio: "",
    additionalContent: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [loading, setLoading] = useState(false);

  const handleMemberChange = (teamIndex, memberIndex, e) => {
    const { name, value } = e.target;
    const updatedTeams = [...formData.teams];
    updatedTeams[teamIndex][memberIndex][name] = value;
    setFormData({ ...formData, teams: updatedTeams });
  };

  const addMember = (teamIndex) => {
    const updatedTeams = [...formData.teams];
    updatedTeams[teamIndex].push({
      name: "",
      class: "",
      contactNo: "",
    });
    setFormData({ ...formData, teams: updatedTeams });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    toast.info("Submitting Form");
    const data = {
      schoolId: formData.schoolId,
      event: formData.event,
      teams: JSON.stringify(
        formData.teams[0].map((t) => ({
          name: t.name,
          class: t.class,
          contactNo: t.contactNo,
        }))
      ),
      audio: formData.audio,
      additionalContent: formData.additionalContent,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_GOOGLE_SCRIPT_URL_UMEED_SCHOOL_INDI_STUDENT_REG,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.result !== "error") {
        toast.success("Form Submitted Successfully!");
        setFormData({
          event: "",
          schoolId: "",
          teams: [[{ name: "", class: "", contactNo: "" }]],
          audio: "",
          additionalContent: "",
        });
        navigate('/umeed')
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
      // console.error(
      //   "Error:",
      //   error.response ? error.response.data : error.message
      // );
    } finally {
      setLoading(false);
    }
  };
  if (!data) {
    return (
      <div className="text-center p-4">
        <h2 className="text-xl font-bold">Redirecting...</h2>
      </div>
    );
  }
  return (
    <div className="max-w-xl mx-auto p-4 bg-custom-bg rounded-2xl shadow-md">
      <h2 className="text-2xl font-bold mb-4">Student Registration Form</h2>
      <form onSubmit={handleSubmit}>
        {/* Event Selection */}
        <div className="mb-4">
          <h1 className="block mb-2 text-sm font-medium text-gray-700">
            Event : {data ? data.event : ""}
          </h1>
          {/* <select
            name="event"
            value={formData.event}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="">Select an event</option>
            <option value="Nrityangan(Solo/Duet)">
              Nrityangan (Solo / Duet)
            </option>
            <option value="Nrityangan(Group)">Nrityangan (Group)</option>
            <option value="Mastermind">Mastermind</option>
            <option value="Resolved">Resolved</option>
            <option value="Einsteins Playground">Einstein’s Playground</option>
            <option value="Chitrahaar">Chitrahaar</option>
            <option value="Dhwani">Dhwani</option>
            <option value="Shay Aur Maat">Shay Aur Maat</option>
          </select> */}
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            School ID
          </label>
          <input
            type="text"
            name="schoolId"
            placeholder="Schoold Id"
            value={formData.schoolId}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>

        {formData.teams.map((team, teamIndex) => (
          <div key={teamIndex} className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Team {teamIndex + 1}</h3>
            {team.map((member, memberIndex) => (
              <div key={memberIndex} className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Member {memberIndex + 1}
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={member.name}
                  onChange={(e) =>
                    handleMemberChange(teamIndex, memberIndex, e)
                  }
                  className="block w-full p-2 border border-gray-300 rounded mb-2"
                  required
                />
                <input
                  type="number"
                  min="0"
                  name="class"
                  placeholder="Class"
                  value={member.class}
                  onChange={(e) =>
                    handleMemberChange(teamIndex, memberIndex, e)
                  }
                  className="block w-full p-2 border border-gray-300 rounded mb-2"
                  required
                />
                <input
                  type="number"
                  min="0"
                  name="contactNo"
                  placeholder="Contact No"
                  value={member.contactNo}
                  onChange={(e) =>
                    handleMemberChange(teamIndex, memberIndex, e)
                  }
                  className="block w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>
            ))}
            <button
              type="button"
              onClick={() => addMember(teamIndex)}
              className="text-blue-500 hover:underline"
            >
              Add More Members
            </button>
          </div>
        ))}

        {(formData.event === "Nrityangan(Solo/Duet)" ||
          formData.event === "Nrityangan(Group)" ||
          formData.event === "Dhwani") && (
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Audio (if applicable)
            </label>
            <input
              type="text"
              name="audio"
              placeholder="Audio Link"
              value={formData.audio}
              onChange={handleChange}
              className="block w-full p-2 border border-gray-300 rounded mb-2"
            />
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Additional Content (Optional)
          </label>
          <textarea
            name="additionalContent"
            value={formData.additionalContent}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Any additional information or content..."
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 text-white font-bold rounded-md ${
            loading
              ? "bg-blue-700 cursor-not-allowed opacity-70 flex items-center justify-center"
              : "bg-blue-400 hover:bg-blue-500"
          }`}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <svg
                aria-hidden="true"
                role="status"
                className="w-4 h-4 me-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Submitting...
            </span>
          ) : (
            "Submit"
          )}
        </button>
        {/* <button
          type="submit"
          className="w-full py-2 text-white font-bold rounded-md bg-blue-400 hover:bg-blue-500"
        >
          Submit
        </button> */}
      </form>
    </div>
  );
};

export default StudentRerister;
