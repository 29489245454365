import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Initiatives = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="px-4 py-8 md:px-8 lg:px-16">
      <div className="bg-custom-bg sm:p-10 p-3 rounded-lg">
        <h1 className="xl:text-7xl text-3xl md:text-5xl lg:text-6xl font-bold text-custom-gray text-center animated-text">
          Initiatives
        </h1>

        <div className="mt-6 text-base md:text-lg lg:text-xl px-2 sm:px-8 md:px-16 text-justify">
          Fast Forward India (FFI) is a student-run NGO based at IIT (ISM)
          Dhanbad. Since its inception in 2007 and registration under the Regd.
          Act 21, 1988, FFI has been dedicated to serving the community of
          Dhanbad and addressing social, economic, and educational challenges
          faced by rural populations through its various projects and
          initiatives. FFI has become a symbol of determination and zeal in
          eradicating social barriers, earning it the trust of both the IIT
          (ISM) Dhanbad community and the broader public. As the organization
          has evolved, its name has become well-known even beyond the campus.
        </div>
      </div>

      <div className="pt-10 text-3xl md:text-4xl lg:text-5xl font-bold text-custom-gray text-center">
        Our Core Chapters:
      </div>

      <Slider {...settings} className="mt-8 md:w-1/2 mx-auto " ref={sliderRef}>
        {[
          {
            title: "Bloodline",
            description:
              "Bloodline, established in 2007, is dedicated to raising awareness about blood donation and ensuring a steady supply of blood for those in need. The chapter regularly organizes blood donation camps, promotes voluntary donation, and conducts daily drives. It also hosts a major Mega Blood Donation Camp on Republic Day to further support its mission.",
            link: "/initiatives/bloodline",
          },
          {
            title: "Disha",
            description:
              "Disha provides career counseling to students in Dhanbad schools, helping them make informed decisions about their futures. The program offers guidance on various career paths and opportunities. Additionally, Disha supports students at risk of dropping out after 10th or 12th grade, offering essential direction to help them stay in school and choose suitable career paths.",
            link: "/initiatives/disha",
          },
          {
            title:
              "ESCAPE (English Speaking Course And Personality Enhancement)",
            description:
              "ESCAPE targets children from underprivileged backgrounds, aiming to bridge communication gaps and improve speaking skills. The program focuses on enhancing English speaking, basic computer education, and writing abilities. ESCAPE provides English courses, personality development sessions, and essential computer education to help students integrate more effectively into society.",
            link: "/initiatives/escape",
          },
          {
            title: "Jagriti",
            description:
              "Jagriti focuses on empowering underprivileged students with vital computer education and skills for the digital age. Jagriti offers tuition and Navodaya Entrance Exam (JNVST) preparation to children from nearby slums. At the FFI Centre, students receive basic education, practice tests, and support for IX and X board exams. The initiative aims to help students reconnect with society and build a foundation for a brighter future.",
            link: "/initiatives/jagriti",
          },
        ].map((initiative, index) => (
          <div
            key={index}
            className="p-4 md:p-6  h-96 md:h-80 lg:p-8 text-center rounded-lg bg-custom-bg"
          >
            <a
              href={initiative.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700 font-bold text-xl md:text-2xl lg:text-3xl transition-colors duration-300"
            >
              {initiative.title}
            </a>
            <p className="mt-2 text-base md:text-lg lg:text-xl mx-auto overflow-hidden text-ellipsis text-justify">
              {initiative.description}
            </p>
          </div>
        ))}
      </Slider>

      <div className="flex justify-center mt-6">
        {[0, 1, 2, 3].map((index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-4 h-4 mx-2 rounded-full cursor-pointer border-2 ${
              currentIndex === index
                ? "bg-caribbeangreen-500 border-caribbeangreen-500"
                : "bg-white border-gray-400"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Initiatives;
