import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Bloodline = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    sliderRef.current.slickGoTo(index); 
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-center text-custom-red mb-6 animated-text">
        Bloodline
      </h1>
      
      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Overview</h2>
        <p className="text-base sm:text-lg">
          Bloodline aims to spread awareness among the people about blood donation and conducts blood donation camps regularly. Bloodline focuses on organizing Blood Donation Camps, spreading Blood Donation Awareness, voluntary Blood Donation on a daily basis, and conducting Mega Blood Donation Camps on Republic Day.
        </p>
        <p className="mt-4 text-base sm:text-lg">
          We collaborate with colleges, corporates, and RWAs to organize innovative and engaging blood donation camps end-to-end.
        </p>
        <p className="mt-4 text-base sm:text-lg">
          Street plays, competitions, and sessions are targeted at inspiring people, especially first-timers, to come out and donate blood.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">About</h2>
        <p className="text-base sm:text-lg text-justify">
          Bloodline was started in 2007 sighting the lack of awareness among people in this part of the country regarding the importance of blood donation. It was a major challenge since the situation was further hampered by the low literacy rate and the backward economy of this region. Even before man understood the true nature of blood and its functions, the importance of blood for the human body remained disputed. One simple reason for this was the basic understanding that excessive loss of blood can cause death. But unlike old times when a serious injury, AIDS, or a pregnancy complication would mean certain death, the improvement in blood preservation technology has made it possible to transfuse blood from a healthy person to one in need even if a donor with the same blood group isn’t readily available. However, even today, the demand exceeds the available preserved blood mostly due to lack of voluntary donations owing to little awareness and misconceptions about safe blood donations. One of the most ambitious and unique projects by a student-run NGO, “Bloodline” is a voluntary blood donation society under Fast Forward India. Bloodline caters to the needs of blood transfusion of people residing in Dhanbad and adjacent areas. Our aim is to spread awareness about voluntary blood donations and to encourage people towards doing it regularly. In India, the annual collection of blood is only 5.5-6 million units against the requirement of about 8.5 million units. It is sad that if only 3% of India's eligible population regularly donates their blood, there will be no shortage of blood and its components in blood banks. This would mean that a significant number of deaths could be avoided if people donate blood regularly and voluntarily so that safe blood is always available. But the main reason behind this sadistic situation is the unawareness, myths, and misconceptions that are still obsessing the minds of Indian people even in the 21st Century.
        </p>
      </section>
      
      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Our Vision</h2>
        <p className="text-base sm:text-lg">
          No one shall die in Dhanbad for the want of blood. This has been our motto since the inception of Bloodline. To achieve this, our current objective is to register at least 1,000 new donors in a year by means of donor motivation, promotion of the concept of voluntary blood donation, and repeating regular voluntary blood donation campaigns.
        </p>
      </section>
      
      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Handling Helpline</h2>
        <p className="text-base sm:text-lg">
          Through helpline requests, we help address immediate blood requirements by patients.
        </p>
        <p className="mt-4 text-base sm:text-lg">
          Fill this form, and we will find a donor.
        </p>
      </section>
      
      <div className="relative overflow-hidden">
        <Slider {...settings} ref={sliderRef} className="whitespace-nowrap">
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884124/FFI/Bloodline/gjxqhb5juewe7sfxxvvv.jpg"
              alt="Bloodline 6"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884128/FFI/Bloodline/fuchplikcz90awoiesc0.jpg"
              alt="Bloodline 7"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884048/FFI/Bloodline/xufhqkphzifei3hlguia.webp"
              alt="Bloodline 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884058/FFI/Bloodline/rbwi8kishaocszbrcyvk.webp"
              alt="Bloodline 2"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722892822/FFI/Bloodline/hfvhupmzgj316f9mvbir.jpg"
              alt="Bloodline 7"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884058/FFI/Bloodline/tqs5wdimrzixcx1z9jkn.webp"
              alt="Bloodline 3"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884058/FFI/Bloodline/zwrsllcshive8awolegh.jpg"
              alt="Bloodline 4"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="inline-block w-full h-[400px] sm:h-[500px] md:h-[600px] lg:h-[648px]">
            <img
              src="https://res.cloudinary.com/dvjschjlg/image/upload/v1722884124/FFI/Bloodline/uajnxysfnlwfk0qvhfgp.webp"
              alt="Bloodline 5"
              className="w-full h-full object-cover"
            />
          </div>
        </Slider>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {Array.from({ length: 8 }).map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-custom-red' : 'bg-white border-gray-400'}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bloodline;
