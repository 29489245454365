import React from "react";

const galleryImages = [
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891213/FFI/Gallery/qhoufhmqotysyqylz3lq.webp",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891212/FFI/Gallery/h9jbhrtuhyhxdhaxjhcq.webp",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891212/FFI/Gallery/cx2nbcxxmtioctnlidj4.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891212/FFI/Gallery/h87mybwgiyjk8sgytlhu.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891211/FFI/Gallery/jvaekjsofttekbdxjexv.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891210/FFI/Gallery/x8mgrjuvmukxcnua2nvm.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891209/FFI/Gallery/vs2xkx19nuvykp7pvocl.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891208/FFI/Gallery/gy78alb6xje2mus7a7vy.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891208/FFI/Gallery/gt0b2mk0gpnxephhh9hf.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891207/FFI/Gallery/kvhzb4azuzlxfukpmwdf.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891119/FFI/Gallery/sipmnmaee6pqigjihhbw.jpg",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722891209/FFI/Gallery/sycvoufi15rgorjgh592.jpg",
];

const Gallery = () => {
  return (
    <div className="py-8 px-4 md:px-8 lg:px-16">
      <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-4 lg:mb-6 text-custom-purple animated-text">
        Gallery
      </h1>
      <p className="text-base md:text-lg lg:text-xl text-center mb-12">
        Explore our collection of moments captured throughout our journey. Each
        image tells a story of the events and activities that have defined our
        milestones.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {galleryImages.map((url, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg group shadow-lg"
          >
            <img
              src={url}
              alt={`Gallery ${index + 1}`}
              className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
