import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const escapeImages = [
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722888468/FFI/ESCAPE/icszqg6qy8xalgkfqgam.webp",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722888468/FFI/ESCAPE/ujjqhbxkr9o7gh9dasxb.webp",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722888468/FFI/ESCAPE/whk3nvz2juyc300rmb43.webp",
  "https://res.cloudinary.com/dvjschjlg/image/upload/v1722888468/FFI/ESCAPE/trcqr82hinutd1rlllp5.webp",
];

const Escape = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-center text-blue-500 mb-6 animated-text">
        ESCAPE
      </h1>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Overview</h2>
        <p className="text-base sm:text-lg">
          The importance of English in the current generation is unparalleled. While it is indispensable in the urban scenario, English seems to be steadily spreading its roots into the rural areas as well. For a leading developing nation, it is important that the generations to come are well equipped with knowledge of the language and are capable of facing the everyday challenges of modern society.
        </p>
        <p className="text-base sm:text-lg mt-4">
          Fast Forward India aims at strengthening the roots of our nation. ESCAPE is an extension of the same. ESCAPE (English Speaking Course And Personality Enhancement) aims at spreading not just knowledge of the language but also communication skills and overall personality enhancement to those with limited resources.
        </p>
        <p className="text-base sm:text-lg mt-4">
          It aims at providing a platform for building confidence, encouraging creativity, and developing skills which help them in various real-world situations.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Establishment</h2>
        <p className="text-base sm:text-lg">
          Over the years the number of students has considerably increased. The number of volunteers is also increasing steadily, along with their dedication towards the students. ESCAPE has also organized “Pratibha” and “Dream India”, the drama and dance competitions respectively.
        </p>
        <p className="text-base sm:text-lg mt-4">
          A free library under FFI is also being set up with active volunteering from ESCAPE, along with an “ESCAPE Documentary” to showcase the importance of English and personality enhancement, and to demonstrate the working and progress of ESCAPE. “Aayam”, a first-of-its-kind “Hinglish Magazine” is also being worked upon, comprising of all the latest and upcoming events, weekly articles, and updates.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Working</h2>
        <p className="text-base sm:text-lg">
          There are FFI centers near the IIT(ISM) campus where ESCAPE classes are conducted every week. Every day a batch of volunteers is appointed to the center to teach the children for 1 hour per volunteer. Monday to Friday classes are scheduled from 6:00-8:00 pm, so a total of two volunteers are appointed every day. Each volunteer devotes his/her one hour for this noble cause. ESCAPE conducts tests regularly to check their improvement. On Saturdays and Sundays, we conduct classes for the Abhoya Sundari Indrdhanush Orphanage students (60 students) near the Hirapur area. To enhance their personality, we show them videos, play interactive games, and try to increase their communication skills.
        </p>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Achievements</h2>
        <ul className="list-disc pl-4 mt-4 text-base sm:text-lg">
          <li className="mb-2">164 students have been imparted computer education in the previous 6 batches successfully at our center.</li>
          <li className="mb-2">Among 29 inmates from KARAGAR JAGRITI CHAPTER, 14 inmates have been retracted on the righteous path and are now earning honestly.</li>
          <li className="mb-2">“Dwanda” a computer quiz for the slum students has been organized on an annual basis.</li>
          <li className="mb-2">“Tulika” a painting competition is an annually organized event.</li>
          <li className="mb-2">Our student Rajni got 1st rank in the interview for Biotech course in P.K. Roy Memorial College.</li>
          <li className="mb-2">A student Sachin of our batch has got the job as a computer operator in the ISM, Emrald Hostel.</li>
        </ul>
      </section>

      <section className="bg-custom-bg p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-custom-gray mb-4">Glimpse</h2>
      </section>

      <div className="relative overflow-hidden">
        <Slider {...settings} ref={sliderRef} className="whitespace-nowrap">
          {escapeImages.map((url, index) => (
            <div
              key={index}
              className="inline-block w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]"
            >
              <img
                src={url}
                alt={`Escape Image ${index + 1}`}
                className="object-cover w-full h-full"
              />
            </div>
          ))}
        </Slider>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {escapeImages.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-3 h-3 sm:w-4 sm:h-4 rounded-full cursor-pointer border-2 ${
                currentIndex === index
                  ? "bg-caribbeangreen-500 border-caribbeangreen-500"
                  : "bg-white border-gray-400"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Escape;
